<template>
    <div class="Favorites">
        <el-page-header @back="backPrivious" content="我的收藏列表"></el-page-header>
        <template v-if="list && list.length">
            <div class="padding-10 f-c-b f1 hover" v-for="(item, index) of list" :key="index"
                @click="toDetail(item.openSearchFlag, item.productId)">
                <div class="f-c">
                    <el-image class="borderRa-10 mr-20 wh-100" :src="item.img1Url"></el-image>
                    <div class="f-cl favorites-info">
                        <span class="mb-10 fs-20">{{ item.productName }}</span>
                        <span class="col-red fs-14">{{ item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'
                        }}</span>
                    </div>
                </div>
                <el-button round v-show="item.openSearchFlag == 'N'" @click="cancleCollect(item.productId)">取消收藏</el-button>
            </div>
            <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </template>
        <el-empty v-else description="暂无内容"></el-empty>
    </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
    name: '',
    mixins: [PaginationRetention],
    inject: ['reload'],
    data() {
        return {
            list: [],
        }
    },
    methods: {
        getDate() {
            this.findProductFavoritesList(this.paging, this.pagingSize)
        },
        // 收藏列表
        findProductFavoritesList(page = this.page, size = this.size) {
            let that = this;
            that.$http.findProductFavoritesList({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    that.list = res.data.records;
                    that.total = res.data.total;
                    that.currentPage = res.data.current;
                }
            })
        },
        toDetail(openSearchFlag, productId) {
            openSearchFlag == 'Y' ? this.$router.push({
                name: 'ProductDetail',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: productId
                    }))
                }
            }) : ''
        },
        cancleCollect(productId) {
            let that = this;
            that.$confirm('是否取消收藏？', '提示').then(() => {
                that.$http.addCancelProductFavorites({
                    userId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    productId: productId,
                    pfState: "N"
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.data, 'success')
                        setTimeout(() => {
                            that.reload()
                        }, 1500)
                    }
                })
            }).catch(() => { })
        }
    },
}

</script>

